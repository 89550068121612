@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
button[type="button"],
button[type="submit"] {
  background: #3e79f7;
}
button span {
  color: #fff;
}
.PricingCard .card-btn {
  background-color: #3e79f7 !important;
  color: #fff;
}
.PricingCard .card-btn:hover {
  color: #3e79f7;
}
.ant-modal-close span {
  background: #3e79f7;
}
body {
  position: relative;
}
.stiky_top_header_nav {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
}
/* use custom background image because tailwind is not work  */
.use_custom_Css_img_for_bg {
  background-image: url("../public/mainbg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.ant-picker-header-super-prev-btn,
.ant-picker-header-next-btn,
.ant-picker-header-super-next-btn,
.ant-picker-header-prev-btn {
  background: #3e79f7 !important;
  color: #fff;
}
/* use custom background image because tailwind is not work  */
